.loader {
    position: relative;
    overflow: hidden;
    border-right: 3px solid;
    width: 0px;
    animation: typewriter 2s steps(10) infinite alternate, blink 0.5s steps(10) infinite;
}

.loader-text {
    font-size: 40px;
    font-weight: 700;
    background: linear-gradient(to right, #F4F4F5, #A556F6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@keyframes typewriter {
    0% {
        width: 0px;
    }
    100% {
        width: 240px;
    }
}

@keyframes blink {
    0% {
        border-right-color: rgba(255, 255, 255, .75);;
    }
    100% {
        border-right-color: transparent;
    }
}

/* From Uiverse.io by mobinkakei */
.loader2 {
    width: 100px;
    height: 100px;
    margin: auto;
    box-sizing: border-box;
    border-radius: 50%;
    border-top: 5px solid #e74c3c;
    position: relative;
    animation: load 2s linear infinite;
}

.loader2::before, .loader2::after {
    content: '';
    width: 100px;
    height: 100px;
    position: absolute;
    left: 0;
    top: 0;
    box-sizing: border-box;
    border-radius: 50%;
}

.loader2::before {
    border-top: 5px solid #e67e22;
    transform: rotate(120deg);
}

.loader2::after {
    border-top: 5px solid #3498db;
    transform: rotate(240deg);
}

.loader2 span {
    position: absolute;
    font-size: small;
    width: 100px;
    height: 100px;
    color: #fff;
    text-align: center;
    line-height: 100px;
    animation: a2 2s linear infinite;
}

@keyframes load {
    to {
        transform: rotate(360deg);
    }
}

@keyframes a2 {
    to {
        transform: rotate(-360deg);
    }
}
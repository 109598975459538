.btn-google-gemini {
    @apply w-full h-[922px]
}

@media screen and (max-width: 900px) {
    .btn-google-gemini {
        @apply w-full h-[880px]
    }
}

@media screen and (max-width: 760px) {
    .btn-google-gemini {
        @apply w-full h-[925px]
    }
}

@media screen and (max-width: 560px) {
    .btn-google-gemini {
        @apply w-full h-[910px]
    }
}

@media screen and (max-width: 390px) {
    .btn-google-gemini {
        @apply w-full h-[890px]
    }
}

.playground-header-text {
    font-size: 40px;
    @apply pointer-events-none z-10 text-center font-bold leading-none tracking-tighter text-transparent
}

.playground-badge-categories {
    transition: all 0.3s;
    @apply w-fit px-4 py-1 rounded-full bg-[#a1a1aa1a] text-[#F9F9FA] text-[14px]
}
.playground-badge-categories:hover {
    @apply bg-[#a1a1aa33] text-[#6665F1]
}

.playground-badge-categories-active {
    @apply bg-[#a1a1aa33] text-[#6665F1]
}

@media screen and (max-width: 800px){
    .playground-header-text {
        font-size: 36px;
        @apply pointer-events-none z-10 text-center font-bold leading-none tracking-tighter text-transparent
    }

    .explore-sort-by {
        width: 170px;
    }
}

@media screen and (max-width: 400px){
    .playground-header-text {
        font-size: 26px;
        @apply pointer-events-none z-10 text-center font-bold leading-none tracking-tighter text-transparent
    }

    .explore-sort-by {
        width: 130px;
    }
}
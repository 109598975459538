
.menu-bar-floating-nav {
    display: none;
}

.icon-button-menu-bar {
    @apply relative text-neutral-50 items-center flex hover:bg-[#30303171] transform duration-300 rounded-full p-2
}

.icon-button-menu-bar-active {
    @apply relative text-neutral-400 items-center flex hover:bg-[#30303171] transform duration-300 rounded-full p-2
}

.blogs-content-left {
    @apply col-span-3 h-full bg-gradient-to-r from-[#121212] to-[#a1a1aa0f] px-4
}

.blogs-content-left-btn {
    padding: 10px 0;
    transition: all ease-in-out 0.5s;
    @apply w-full mb-4 block text-center text-[#A1A1AA] hover:text-[#121212] rounded border hover:bg-[#A1A1AA]
}

.blogs-btn-active {
    padding: 10px 0;
    @apply w-full mb-4 rounded block text-center text-[#121212] bg-[#A1A1AA]
}

.right-recent-item {
    border: rgba(161, 161, 170, 0.34) 1px solid;
    opacity: 0.8;
    transition: all ease-in-out 0.3s;
    @apply flex h-[45px] overflow-hidden px-5 py-2 mt-3 rounded-3xl
}

.right-recent-item:hover {
    opacity: 1;
    @apply bg-[#12121270] text-[#fff] shadow-[#A1A1AA40] shadow
}

@media screen and (max-width: 1024px) {
    .menu-bar-floating-nav {
        display: none;
    }

    .blogs-content-right {
        display: none;
        @apply col-auto
    }

    .blogs-content-center {
        @apply col-span-9
    }

    .blogs-content-left {
        @apply col-span-3 h-full bg-gradient-to-r from-[#121212] to-[#a1a1aa0f] px-4
    }
}

@media screen and (max-width: 768px) {
    .menu-bar-floating-nav {
        display: block;
    }

    .blogs-content-center {
        @apply col-span-12
    }

    .blogs-content-icon {
        display: block;
    }

    .blogs-content-icon:hover {
        position: fixed;
        top: 14%;
        left: -4px;
        background-position: -200% -100%;
    }

    .blogs-content-left {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 85px;
        left: -120%;
        border-radius: 20px;
        opacity: 1;
        transition: all 0.6s ease-in-out;
        @apply h-fit
    }

    .blogs-content-left.active {
        display: none;
    }
}

@media screen and (max-width: 440px) {
    .menu-bar-floating-nav {
        display: block;
    }

    .blogs-content-left-recent {
        display: none;
    }
}
.button-border-animation {
    --color: #A556F6;
    cursor: pointer;
    position: relative;
    color: var(--color);
    padding: 5px 20px;
    overflow: hidden;
    display: flex;
    transition: all 1.5s;
}

.button-border-animation:hover {
    /*background: var(--color);*/
    background: linear-gradient(45deg, #6665F1, #A556F6);
    color: #F4F4F5;
    border-radius: 10px;

}

.button-border-animation span {
    position: absolute;
    display: block;
}

.button-border-animation span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, var(--color));
    animation: btn-anim1 4s linear infinite;
}

@keyframes btn-anim1 {
    0% {
        left: -100%;
    }

    50%,
    100% {
        left: 100%;
    }
}

.button-border-animation span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, var(--color));
    animation: btn-anim2 4s linear infinite;
    animation-delay: 1s;
}

@keyframes btn-anim2 {
    0% {
        top: -100%;
    }

    50%,
    100% {
        top: 100%;
    }
}

.button-border-animation span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, var(--color));
    animation: btn-anim3 4s linear infinite;
    animation-delay: 2s;
}

@keyframes btn-anim3 {
    0% {
        right: -100%;
    }

    50%,
    100% {
        right: 100%;
    }
}

.button-border-animation span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, var(--color));
    animation: btn-anim4 4s linear infinite;
    animation-delay: 3s;
}

@keyframes btn-anim4 {
    0% {
        bottom: -100%;
    }

    50%,
    100% {
        bottom: 100%;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&family=Lora:ital@0;1&family=Merriweather&family=Poppins&family=Roboto&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Lora", serif;
    /* ... */
}

.content-rich-text {
    font-family: inherit; /* This will allow the editor content to use selected fonts */
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
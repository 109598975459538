.new-play-form-right {
    @apply col-span-6 px-2
}

.new-play-form-left {
    @apply col-span-6 px-2
}

@media screen and (max-width: 680px) {
    .new-play-form-right {
        @apply col-span-12 px-2
    }

    .new-play-form-left {
        @apply col-span-12 px-2
    }

}


.account-content-left {
    @apply col-span-4 px-8 bg-gradient-to-r from-[#121212] to-[#a1a1aa0f]
}

.account-content-right {
    @apply col-span-8 px-0
}

.account-playground-content {
    @apply grid grid-cols-2 gap-5 px-5 mt-5
}

.button-ai-summary-add-new-model {
    @apply w-2/6 inline-flex justify-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none hover:bg-gray-600 focus:outline-1 focus:outline-white
}

@media screen and (max-width: 1000px) {
    .container {
        padding: 1px !important;
    }
    .account-content-left {
        @apply col-span-5 px-8 bg-gradient-to-r from-[#121212] to-[#a1a1aa0f] mb-6
    }

    .account-content-right {
        @apply col-span-7 px-0
    }

    .account-playground-content {
        @apply grid grid-cols-1 gap-4 px-5 mt-5
    }

}

@media screen and (max-width: 740px) {
    .account-content-left {
        @apply col-span-12 px-8 bg-gradient-to-r from-[#121212] to-[#a1a1aa0f]
    }

    .account-content-right {
        @apply col-span-12 px-0
    }

}

@media screen and (max-width: 500px) {
    .button-ai-summary-add-new-model {
        @apply w-3/6 inline-flex justify-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none hover:bg-gray-600 focus:outline-1 focus:outline-white
    }
}

@media screen and (max-width: 350px) {
    .account-text-user-active {
       display: none;
    }

    .button-ai-summary-add-new-model {
        @apply w-4/6 inline-flex justify-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none hover:bg-gray-600 focus:outline-1 focus:outline-white
    }
}
.blog-detail {
    @apply text-[#F4F4F5] mb-8 mt-6 w-9/12 m-auto
}

.blog-detail-button-follow {
    font-size: 14px;
}

.blog-image-title {
    @apply relative overflow-hidden rounded-t-2xl h-[36vh]
}

@media screen and (max-width: 868px) {
    .blog-image-title {
        @apply relative overflow-hidden rounded-t-2xl h-[30vh]
    }
}


@media screen and (max-width: 568px) {
    .blog-detail {
        @apply w-11/12
    }

    .blog-image-title {
        @apply relative overflow-hidden rounded-t-2xl h-[25vh]
    }
}

@media screen and (max-width: 468px) {
    .blog-image-title {
        @apply relative overflow-hidden rounded-t-2xl h-[19vh]
    }
    .blog-detail-button-follow {
        font-size: 11px;
    }
}
.menu-component {
    background: linear-gradient(90deg, #0E0E11, #1A1A1D, #0E0E11);
}

.NavbarItems {
    justify-content: center;
    align-items: center;
    font-size: 18px;
    padding: 0 0;
    @apply h-[80px] flex py-3 container
}

.navbar-logo {
    justify-self: start;
    cursor: pointer;
}

.navbar-logo-img {
    content: url('https://w404.net/assets/images/logo2.png');
    width: 75%;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 30px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: center;
}

.nav-links {
    color: #A1A1AA;
    text-decoration: none;
    padding: 0.2rem 0.7rem;
    position: relative;
    transition: color 1s; /* Transition color if desired */
}

.nav-links:hover {
    color: #F4F4F5; /* Optional: if you want to change color on hover */
}

.nav-links::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0; /* Start with width 0 */
    height: 2px;
    background: linear-gradient(90deg, #e3eeff, #A556F6, #5c98fe);
    border-radius: 1px;
    transition: width 0.9s ease-out; /* Transition width over 3 seconds */
}

.nav-links:hover::after {
    width: 100%; /* Expand to full width on hover */
}

.menu-icon {
    display: none;
}

.nav-links-mobile {
    display: none;
}

@media screen and (max-width: 768px) {
    .NavbarItems {
        position: relative;
    }
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 85px;
        left: -120%;
        border-radius: 20px;
        opacity: 1;
        transition: all 0.6s ease-in-out;
    }
    .navbar-logo-img {
        content: url("https://w404.net/assets/images/logo1.png");
        width: 65%;
    }
    .nav-menu.active {
        background: linear-gradient(180deg, #1A1A1D, #0E0E11, #0E0E11);
        left: 0;
        opacity: 1;
        transition: all 0.6s ease-in-out;
        z-index: 100;
    }
    .nav-links {
        text-align: center;
        padding: 1rem;
        width: 100%;
        display: table;
    }
    .nav-links:hover {
        color: #F4F4F5; /* Optional: if you want to change color on hover */
    }

    .nav-links::after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 0; /* Start with width 0 */
        height: 2px;
        background: linear-gradient(90deg, #e3eeff, #A556F6, #5c98fe);
        border-radius: 1px;
        transition: width 0.9s ease-out; /* Transition width over 3 seconds */
    }
    .navbar-logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(70%, 60%);

        font-size: 2rem;
        cursor: pointer;
    }
    .nav-btn {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-15%, 55%);
    }
    .nav-links-mobile {
        display: none;
    }
}

@media screen and (max-width: 400px) {
    .NavbarItems {
        position: relative;
    }
    .nav-menu {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 85px;
        left: -120%;
        border-radius: 20px;
        opacity: 1;
        transition: all 0.6s ease-in-out;
        @apply w-full h-[450px]
    }
    .navbar-logo {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(30%, 50%);
    }
    .navbar-logo-img {
        content: url("https://w404.net/assets/images/logo1.png");
        width: 50%;
    }
    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(7%, 70%);
        font-size: 2rem;
        cursor: pointer;
    }
    .nav-btn {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-7%, 50%);
    }
    .nav-links-mobile {
        display: block;
    }
}


.isActive {
    color: #F4F4F5; /* Active link color */
}

.isActive::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%; /* Ensure the underline spans the full width for active state */
    height: 2px;
    background: linear-gradient(90deg, #e3eeff, #A556F6, #5c98fe);
    border-radius: 1px;
}